import React from "react";
import Layout from "../../../other/layout";
import MarketingMaterials from "../../../components/AC-StaticPages/products/MarketingMaterials";
import SEO from "../../../other/seo";

export default function marketingMaterials() {
  return (
    <Layout>
      <SEO title="Marketing Materials" />

      <MarketingMaterials />
    </Layout>
  );
}
