import React from "react";
import { Link } from "gatsby";
import LogoDeignServices from "../../../assets/img/LogoDeignServices.jpg";
import business_cards from "../../../assets/img/BusinessCards_Thumbnail.jpg";
import postcards from "../../../assets/img/postcards.jpg";
import booklets from "../../../assets/img/Booklets_Thumbnail.jpg";
import posters from "../../../assets/img/Posters_Thumbnail.jpg";
import labels from "../../../assets/img/Stickers_Thumbnail.jpg";

import styles from "../style/styles.module.css";

export default function MarketingMaterials() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Full Colour Print Marketing Products</h1>
          <h2>Affordable full-colour printing.</h2>
          <img
            className={styles.hero}
            src={LogoDeignServices}
            alt="Brand logo on various products Image"
          />

          <p className={styles.productMargin}>
            Help customers grab attention and win business with a wide variety
            of eye-popping, full-colour products. It's easy and affordable with
            full-colour printing.
          </p>

          <ul>
            <li>Huge selection of full-colour products at wholesale pricing</li>
            <li>Faster quotes, easier ordering and quicker turnaround</li>
            <li>Many stocks and finishing options, plus custom capabilities</li>
          </ul>
          <p className={styles.productMargin}>
            Entrust your customers' colour brochures, postcards, flyers, and
            business cards and more to a partner who is committed to making
            full-colour sales profitable for you. Call us at{" "}
            <a href="tel:800-490-6327">800.490.6327</a> to get started.
          </p>
          <hr className={styles.productHr} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Products Category Highlights</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img src={business_cards} alt="Business card Image"></img>
              <h3>Business Cards</h3>
            </div>
            <div className={styles.card}>
              <img src={postcards} alt="Postcards Image"></img>
              <h3>Postcards</h3>
            </div>
            <div className={styles.card}>
              <img src={booklets} alt="Booklets Image"></img>
              <h3>Booklets</h3>
            </div>
            <div className={styles.card}>
              <img src={posters} alt="Poster Image"></img>
              <h3>Posters</h3>
            </div>
            <div className={styles.card}>
              <img src={labels} alt="Labels Image"></img>
              <h3>Labels</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
